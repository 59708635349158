import React, { useState, useEffect } from "react"
import {
  Layout,
  FlexCol,
  Parallax,
  DoubleCTASection,
  AnimatedLink,
} from "@components/layout"
import { AnimatedButton, TextInput } from "@components/shared"

import { SheetsApi } from "@api"

import {
  page__wrapper,
  page__content,
  option__wrapper,
  selector__wrapper,
  selector__button,
} from "./styles/tech.module.scss"

const SingleOption = ({ el, onChange }) => {
  return (
    <div className={option__wrapper}>
      <div className="row">
        <input onChange={onChange} type="checkbox" name={el} value={el}></input>
        <label>{el}</label>
      </div>
    </div>
  )
}

const StackSelector = (location) => {
  const [state, setState] = useState({ stack: [], email: "", msg: "" })
  const options = ["JS", "Python", "React"]
  const { href } = location.location

  // let techStack = state.stack.join(",")

  let url = ""
  if (href) {
    url = href.split("=", 2)
  }
  let email = ""
  if (url) {
    email = url[1]
  }

  if (typeof window !== "undefined") {
    if (email) {
      localStorage.setItem("email", email)
    }
  }

  let storageEmail = ""
  if (typeof window !== "undefined") {
    storageEmail = localStorage.getItem("email")
  }

  useEffect(() => {
    if (storageEmail != "undefined") {
      setState({
        ...state,
        email: storageEmail,
      })
    }
  }, [])

  const handleSelect = (event) => {
    let stackList = state.stack
    let checked = event.target.checked
    let selectedOption = event.target.value
    console.log(selectedOption)
    if (checked) {
      setState({
        ...state,
        stack: [...state.stack, selectedOption],
      })
    } else {
      let index = stackList.indexOf(selectedOption)
      if (index > -1) {
        stackList.splice(index, 1)
        setState({
          stack: stackList,
        })
      }
    }
  }

  const sendAnswer = (email, stack) => {
    SheetsApi.addAnswer(email, stack).then((data) => {
      console.log(data)
      setState({
        ...state,
        msg: data.message,
      })
    })
  }

  return (
    <div className={selector__wrapper}>
      <div className="selector">
        <h4>Mój stack technologiczny to:</h4>
        {options.map((el, k) => {
          return (
            <SingleOption el={el} key={k} onChange={(el) => handleSelect(el)} />
          )
        })}
      </div>
      <input
        type="text"
        placeholder="Twój email"
        value={state.email}
        onChange={(ev) => {
          setState({
            ...state,
            email: ev.target.value,
          })
        }}
      />
      <div className="row justify-content-center">
        <AnimatedButton
          onClick={() => sendAnswer(state.email, state.stack)}
          className={selector__button}
        >
          Wyślij
        </AnimatedButton>
      </div>
      <div className="row justify-content-center">
        {state.msg != "" ? <h5>{state.msg}</h5> : null}
      </div>
    </div>
  )
}

const Tech = ({ pageContext, location }) => {
  const { page } = pageContext
  return (
    <Layout
      animation={false}
      seo={pageContext.page.seo}
      siteMetaData={pageContext.page.siteMetaData}
    >
      <Parallax height={300}>
        <h4>Twój stack technologiczny</h4>
      </Parallax>
      <div className={page__wrapper}>
        <div className={page__content}>
          <StackSelector location={location} />
        </div>
      </div>
    </Layout>
  )
}

export default Tech
